import { CSSProperties } from "react";

export const CardPreviewModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  width: 280,
  transform: "translate(-50%, -50%)",
  "& .MuiCard-root": {
    boxShadow: "none",
    overflow: "inherit",
    backgroundImage: "none",
    backgroundColor: "transparent",
  },
};
export const CardPreviewModalImageStyle: CSSProperties = {
  boxSizing: "border-box",
  borderRadius: "4.75% / 3.5%",
  boxShadow: "1px 1px 8px rgb(0 0 0 / 50%)",
  height: "3.5in",
  width: "2.5in",
  objectFit: "cover",
};

export const CardPreviewSizing: CSSProperties = {
  width: "2.5in",
  height: "3.5in",
  minHeight: "3.5in",
};
export const ChangePrintingCardSizing: CSSProperties = {
  width: "1.5in",
  height: "2.1in",
  borderRadius: "4.75% / 3.5%",
};

export const ResponsiveCardSizing: CSSProperties = {
  width: "100%",
  height: "98%",
};
