export const QUERIES = {
  COLLECTION_USER: "collectionUser",
  COLLECTION_CARDS: "collectionCards",
  COLLECTION_APPRAISE: "collectionAppraise",

  COLLECTION_IMPORTS_PAGED: "collectionImportsPaged",
  COLLECTION_IMPORT_DETAILS: "collectionImportsDetails",

  DECK_USER: "deckUser",
  DECK_DETAILS: "deckDetails",
  DECKS_PUBLIC: "decksPublic",
  DECKS_OWNED_COUNTS: "decksOwnedCounts",

  TRADE_LISTS_PAGED: "tradeListPaged",
  TRADE_LISTS_DETAILS: "tradeListDetails",
  TRADE_LIST_ITEMS: "tradeListItems",
  TRADE_LIST_APPRAISE: "tradeListAppraise",

  MAGIC_CARDS_AUTOCOMPLETE: "magicCardsAutocomplete",
  MAGIC_CARDS_CHART: "magicCardsChart",
  MAGIC_CARDS_DETAILS: "magicCardsDetails",
  MAGIC_CARDS_PAGED: "magicCardsPaged",
  MAGIC_CARDS_PRINTINGS: "magicCardsPrintings",

  SETS: "sets",
  SETS_PAGED: "setsPaged",
  SET: "set",

  LGS_USER: "lgsUser",
  LGS_CARDS: "lgsCards",
  LGS_MATCHED_CARDS: "lgsMatchedCards",
  APP_VERSION: "appVersion",
  APP_USER: "appUser",

  METAGAME_AVAILABLE_FORMATS: "metagameAvailableFormats",
};
