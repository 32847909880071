import { Box, Typography } from "@mui/material";
import {
  ManaFontManaSymbols,
  ReplacedSymbolsOracleText,
} from "modules/common/components/ManaFontSymbols";
import { manaCostToManaFontClasses } from "utils/utils";
import React from "react";
import { CardImageTextProps } from "modules/common/components/CardDetailsDialog/CardImageText";

export const CardEssentialInfo = ({ card }: CardImageTextProps) => {
  return (
    <>
      <Box
        display={"flex"}
        gap={0.25}
        sx={{ mt: { xs: 2, md: 0 } }}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          color={"primary.main"}
          variant={"h1"}
          sx={{
            fontSize: "1.3em",
          }}
        >
          {card.faceName || card.name}
        </Typography>
        <ManaFontManaSymbols
          style={{
            marginLeft: "1px",
          }}
          manaCost={card.manaCost}
          extractClassesFunc={manaCostToManaFontClasses}
        />
      </Box>
      <Typography
        variant={"caption"}
        color={"secondary.main"}
        sx={{
          fontSize: "0.9em",
        }}
      >
        {card.type}
      </Typography>
      <ReplacedSymbolsOracleText
        oracleText={card.text}
        style={{
          fontSize: "0.75em",
          marginLeft: "1px",
          color: "primary.main",
        }}
      />
      <Typography
        sx={{ fontStyle: "italic", fontSize: "0.8em" }}
        variant={"body2"}
        color={"secondary.main"}
      >
        {card.flavorText}
      </Typography>
    </>
  );
};
