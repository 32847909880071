"use client";
import { Box, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";
import React from "react";
import { CardPreviewModalImageStyle } from "utils/styles";
import { CardMediaImage } from "modules/common/components/CardMediaImage";

import { flippableCardLayouts } from "modules/common/model/models";
import {
  AutocompleteMagicCard,
  FinishEnum,
  MagicCard,
} from "modules/cards/model/card";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    padding: 0,
    color: "rgba(0, 0, 0, 0.87)",
    border: "0px solid #dadde9",
    maxWidth: "2.5in",
    maxHeight: "3.5in",
    pointerEvents: "none",
    "& .MuiPaper-root": {
      boxShadow: "none",
      overflow: "inherit",
      backgroundImage: "none",
      backgroundColor: "transparent",
    },
    "& .MuiTooltip-arrow": {
      color: theme.palette.primary.main,
    },
    ...CardPreviewModalImageStyle,
  },
}));

interface Props {
  card: MagicCard | AutocompleteMagicCard;
  children: any;
  isFoil?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

export const TooltipCardImage = (props: Props) => {
  //TODO: isFoil should be passed to the tooltip
  const isFoil = props.isFoil
    ? props.isFoil
    : !props.card.finishes.includes(FinishEnum.NonFoil);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  return (
    <HtmlTooltip
      placement={`${props.placement ? props.placement : "right-start"}`}
      // placement="bottom-end"
      title={
        !mdDown ? (
          <React.Fragment>
            <Box display={"flex"}>
              <CardMediaImage
                isFoil={isFoil}
                card={props.card}
                flipped={false}
                setFlipped={() => 1}
                isTooltip={true}
              />
              {"layout" in props.card ? (
                flippableCardLayouts.includes(props.card.layout) &&
                props.card.otherFaces ? (
                  <CardMediaImage
                    isFoil={isFoil}
                    card={props.card}
                    flipped={true}
                    setFlipped={() => 1}
                    isTooltip={true}
                  />
                ) : null
              ) : null}
            </Box>
          </React.Fragment>
        ) : (
          ""
        )
      }
    >
      {props.children}
    </HtmlTooltip>
  );
};
