"use client";
import React, { useEffect, useState } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { MagicCard } from "modules/cards/model/card";
import { CardSetPrice } from "modules/common/components/CardDetailsDialog/CardSetPrice";
import { flippableCardLayouts } from "modules/common/model/models";
import { CardEssentialInfo } from "modules/common/components/CardDetailsDialog/CardEssentialInfo";
import { CardImageMarketIcons } from "modules/common/components/CardDetailsDialog/CardImageMarketIcons";

export interface CardImageTextProps {
  card: MagicCard;
  isFoil?: boolean;
}

export const CardImageText = ({ card, isFoil }: CardImageTextProps) => {
  const [flipped, setFlipped] = useState<boolean>(false);
  const [displayedCard, setDisplayedCard] = useState<MagicCard>(card);

  useEffect(() => {
    if (card.otherFaces) {
      setDisplayedCard(flipped ? card.otherFaces[0] : card);
    }
  }, [flipped]);

  // if (isFoil === undefined) {
  //   setIsFoil(!card.finishes.includes(FinishEnum.NonFoil));
  // }

  return (
    <Grid
      container
      columnSpacing={3}
      alignItems={"flex-start"}
      justifyContent={"center"}
    >
      <Grid
        item
        xs={"auto"}
        sm={6}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <CardImageMarketIcons
          card={card}
          flipped={flipped}
          setFlipped={setFlipped}
          isFoil={isFoil}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CardEssentialInfo card={displayedCard} />
        {!flippableCardLayouts.includes(card.layout) &&
        card.otherFaces &&
        card.layout !== "art_series"
          ? card.otherFaces.map((face, idx) => (
              <Box key={idx}>
                <Divider
                  sx={{
                    my: 1,
                  }}
                />
                <CardEssentialInfo card={face} />
              </Box>
            ))
          : null}
        <Divider sx={{ my: 2 }} />
        <CardSetPrice card={card} isFoil={isFoil} />
      </Grid>
    </Grid>
  );
};
