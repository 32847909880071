import React, { useRef } from "react";
import { Box, Grid, Icon, Typography } from "@mui/material";
import { Star } from "@mui/icons-material";
import { OverflowTip } from "modules/common/components/OverflowTip";
import { capitalizeFirstLetter } from "utils/utils";
import { MagicCard } from "modules/cards/model/card";

interface Props {
  card: MagicCard;
  isFoil?: boolean;
}

export const CardSetPrice = ({ card, isFoil }: Props) => {
  const rarity =
    card.rarity === "special" ? "timeshifted" : (card.rarity as string);
  const textRef = useRef(null);
  const cardPrice = isFoil
    ? card.price?.value.foil && `€${card.price.value.foil.toFixed(2)}`
    : card.price?.value.default && `€${card.price.value.default.toFixed(2)}`;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          component={"span"}
          sx={{
            position: "relative",
          }}
        >
          {card.isPromo ? (
            <Box
              component={"span"}
              sx={{
                position: "absolute",
                color: "text.secondary",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                zIndex: 100,
                pt: "28px",
                pl: "22px",
              }}
            >
              <Star
                sx={{
                  height: "16px",
                  width: "16px",
                  stroke: "#000",
                  strokeWidth: "1px",
                }}
              />
            </Box>
          ) : null}
          <Grid container>
            <Grid
              item
              xs={2}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Icon
                baseClassName={`ss ss-${card.set?.keyruneCode.toLowerCase()}  ss-2x`}
              />
            </Grid>
            <Grid item xs={6}>
              <OverflowTip value={card.set?.name} textRef={textRef}>
                <Typography
                  ref={textRef}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: 150,
                    maxWidth: "100%",
                  }}
                  variant={"subtitle2"}
                >
                  {card.set?.name}
                </Typography>
              </OverflowTip>
              <Typography color={"secondary"} variant={"caption"}>
                {card?.setCode}#{card?.number}
              </Typography>
            </Grid>
            <Grid item xs={4} display={"flex"}>
              <Grid container justifySelf={"flex-end"}>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    flexGrow={1}
                    gap={0.25}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                      display={"flex"}
                      alignItems={"center"}
                      variant={"subtitle2"}
                    >
                      {cardPrice}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    flexGrow={1}
                  >
                    <Typography
                      variant={"caption"}
                      color={"secondary"}
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      {rarity && capitalizeFirstLetter(rarity)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
