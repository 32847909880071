import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CardMarketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        focusable="false"
        className="svg-inline--fa fa-w-14 fa-fw me-2"
        color="currentColor"
        aria-hidden="true"
        height="15"
        width="15"
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
        // style="vertical-align: -2px;"
      >
        <title>Cardmarket</title>
        <g fill="currentColor" fillRule="nonzero">
          <path d="M4.168 14.093c-.084-.226-1-2.735-2.044-5.597C.038 2.773.094 3.14 1.18 2.73c.55-.21.592-.21.662.014.042.127.592 2.552 1.213 5.386 1.07 4.878 1.156 5.16 1.55 5.54.24.212.606.38.818.395.366 0 .366.014-.113.197-.747.296-.973.254-1.142-.17z"></path>
          <path d="M5.31 12.782c-.268-.268-.282-.465-.282-2.862 0-2.777.085-3.186.663-3.003.424.14 2.2 2.17 2.1 2.41-.126.353-.393.24-1.17-.52l-.746-.734v4.145h4.103l-.282-.366c-.17-.212-.297-.48-.297-.607s.945-1.17 2.1-2.326c1.552-1.552 2.172-2.073 2.34-2.003.128.043.213.17.185.282-.03.112-.875 1.1-1.904 2.17l-1.862 1.96.494.48c.507.493.634 1.028.254 1.17-.127.042-1.396.084-2.82.084-2.41 0-2.608-.014-2.876-.282zM3.224 5.183C2.8 3.18 2.49 1.39 2.52 1.208c.027-.268.168-.367.788-.494l.733-.17V4.69c0 2.284-.013 4.145-.027 4.145-.028 0-.38-1.65-.79-3.652z"></path>
          <path d="M7.143 6.917c-.776-.747-1.27-1.34-1.27-1.508 0-.184.692-.974 1.79-2.102l1.792-1.804-.536-.55c-.297-.31-.494-.62-.452-.705.07-.1.987-.156 2.763-.156 2.285 0 2.666.028 2.736.226.042.126.085 1.325.085 2.678 0 2.848-.07 3.003-.958 2.13l-.507-.523-1.79 1.762C9.61 7.552 8.918 8.13 8.707 8.13c-.198 0-.762-.437-1.565-1.213z"></path>
        </g>
      </svg>
    </SvgIcon>
  );
};
