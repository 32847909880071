import { QUERIES } from "modules/common/query";
import { getQueryClient } from "modules/common/getQueryClient";

export const useReset = () => {
  const queryClient = getQueryClient();

  return {};

  async function resetUser() {
    await queryClient.resetQueries();
    await queryClient.resetQueries({
      queryKey: [QUERIES.DECK_USER],
    });
    await queryClient.resetQueries({
      queryKey: [QUERIES.COLLECTION_CARDS],
    });
    await queryClient.resetQueries({
      queryKey: [QUERIES.DECKS_OWNED_COUNTS],
    });
    await queryClient.resetQueries({
      queryKey: [QUERIES.COLLECTION_APPRAISE],
    });
    await queryClient.resetQueries({
      queryKey: [QUERIES.COLLECTION_USER],
    });
  }
};
