import React, { CSSProperties } from "react";
import { Box, Typography } from "@mui/material";
import { manaSymbolsPairs, planesWalkerPairs } from "utils/utils";
import reactStringReplace from "react-string-replace";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";

interface ManaSymbolsProps {
  manaCost: string;
  extractClassesFunc: (manaCost: string) => string[];
  showColorless?: boolean;
  style?: CSSProperties;
}

export const ManaFontManaSymbols = ({
  manaCost = "",
  extractClassesFunc,
  style,
}: ManaSymbolsProps) => {
  const costs: string[] = extractClassesFunc(manaCost);
  const els = costs.map((el, idx) => {
    return (
      <i
        key={idx}
        className={`ms ms-${el.trim().toLocaleLowerCase()} ms-cost ms-shadow`}
        style={style}
      />
    );
  });

  return costs.length ? (
    <Box display={"flex"} sx={{ flexShrink: 0 }} gap={0.25}>
      {els}
    </Box>
  ) : (
    <></>
  );
};

interface TypeSymbolsProps {
  type: string;
}

export const ManaFontTypeSymbols = ({ type }: TypeSymbolsProps) => {
  const els: any = [];
  if (type.includes(",")) {
    const types = type.split(",").map((t) => t.trim());
    els.push(
      ...types.map((t, idx) => {
        return (
          <i
            key={idx}
            className={`ms ms-${t.toLocaleLowerCase()} ms-shadow ms-fw`}
          />
        );
      })
    );
  } else {
    const t = type.trim();
    els.push(
      <i key={0} className={`ms ms-${t.toLocaleLowerCase()} ms-shadow ms-fw`} />
    );
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      sx={{ flexShrink: 0 }}
      gap={0.25}
    >
      {els}
    </Box>
  );
};

interface ReplacedSymbolsOracleTextProps {
  oracleText: string;
  style?: CSSProperties;
  variant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
}

export const ReplacedSymbolsOracleText = ({
  oracleText = "",
  style,
  variant = "caption",
}: ReplacedSymbolsOracleTextProps) => {
  let replacedOracleText;

  for (const [key, value] of Object.entries(manaSymbolsPairs)) {
    replacedOracleText = reactStringReplace(
      oracleText,
      key,
      (match, i, offset) => (
        <i
          key={`${key}-${i}-${offset}`}
          className={`ms ms-${value.toLocaleLowerCase()} ms-cost ms-shadow`}
          style={{
            ...style,
          }}
        />
      )
    );
    // @ts-ignore
    oracleText = replacedOracleText;
  }

  for (const [key, value] of Object.entries(planesWalkerPairs)) {
    if (key.includes("+")) {
      replacedOracleText = reactStringReplace(
        oracleText,
        key,
        (match, i, offset) => (
          <i
            key={`${key}-${i}-${offset}`}
            className={`ms ms-loyalty-up ms-loyalty-${value.toLocaleLowerCase()}`}
          />
        )
      );
    } else {
      replacedOracleText = reactStringReplace(
        oracleText,
        key,
        (match, i, offset) => (
          <i
            key={`${key}-${i}-${offset}`}
            className={`ms ms-loyalty-down ms-loyalty-${value.toLocaleLowerCase()}`}
          />
        )
      );
    }
    // @ts-ignore
    oracleText = replacedOracleText;
  }

  return oracleText.length ? (
    <Box
      sx={{
        flexShrink: 0,
      }}
    >
      <Typography variant={variant} sx={{ whiteSpace: "pre-line" }}>
        {replacedOracleText}
      </Typography>
    </Box>
  ) : (
    <></>
  );
};
