"use client";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { capitalizeFirstLetter } from "utils/utils";
import { Formats } from "utils/constants";
import { FormatEnum } from "modules/decks/model/deck";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 20,
  // lineHeight: "17px",
  // minWidth: 150
}));

export default function CardDetailsLegalities(props: any) {
  const theme = useTheme();
  const legalitiesArray = [...Object.keys(props.legalities)] as FormatEnum[];
  const colorPalette = {
    // @ts-ignore
    Banned: theme.palette.cardLegalities.banned,
    // @ts-ignore
    Legal: theme.palette.cardLegalities.legal,
    // @ts-ignore
    Restricted: theme.palette.cardLegalities.restricted,
  };
  const formatLegalities = Formats.map((format: FormatEnum, idx: number) => {
    // @ts-ignore
    const legalityColor = colorPalette[props.legalities[format]];
    const legalityStatus = legalitiesArray.includes(format) ? (
      <Item elevation={2} sx={{ bgcolor: legalityColor }}>
        <Typography
          variant="caption"
          sx={{ lineHeight: "17px" }}
          color={theme.palette.primary.contrastText}
        >
          {props.legalities[format as FormatEnum].toUpperCase()}
        </Typography>
      </Item>
    ) : (
      <Item
        elevation={2}
        sx={{
          bgcolor:
            // @ts-ignore
            theme.palette.cardLegalities.notLegal,
        }}
      >
        <Typography
          variant="caption"
          sx={{ lineHeight: "17px" }}
          color={theme.palette.primary.contrastText}
        >
          NOT LEGAL
        </Typography>
      </Item>
    );

    return (
      <Grid key={idx} item xs={12} sm={6}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr 1fr", sm: "1fr 2fr" },
            gap: { xs: 1, sm: 1 },
          }}
        >
          {legalityStatus}
          <Typography
            variant={"body2"}
            sx={{ lineHeight: "17px" }}
            color={theme.palette.primary.main}
          >
            {capitalizeFirstLetter(format)}
          </Typography>
        </Box>
      </Grid>
    );
  });

  return (
    <Grid container spacing={1}>
      {formatLegalities}
    </Grid>
  );
}
