import {
  DeckCard,
  DeckDetails,
  DeckLocationEnum,
} from "modules/decks/model/deck";
import {
  DeckLocationNameMap,
  ManaColorChartColorMapping,
  ManaColorNameMap,
} from "utils/constants";
import { MagicCard, ManaColorEnum } from "modules/cards/model/card";
import _groupBy from "lodash/groupBy";
import { CardGrouping } from "modules/common/model/models";
import { SubmittedDeckItem } from "modules/metagame/model/submitted-decks";
import { format } from "date-fns";
import { CollectionCard } from "modules/collections/details/model/collection";
import { TradeItem } from "modules/tradeLists/model/tradeList";

export const illustrationLink = (scryfallId: string, isBack?: boolean) => {
  return `https://api.scryfall.com/cards/${scryfallId}?format=img&version=normal&face=${
    isBack ? "back" : "front"
  }`;
};

export const capitalizeFirstLetter = function capitalizeFirstLetter(
  string: string
) {
  return string?.charAt(0).toUpperCase() + string.slice(1);
};

export const getOccurrences = <K>(items: K[]) => {
  const counts = new Map<K, number>();

  items.forEach((c) => {
    if (!counts.has(c)) {
      counts.set(c, 1);
    } else {
      let val = counts.get(c) as number;
      val++;
      counts.set(c, val);
    }
  });

  return counts;
};

export const repeat = (array: any[], n: number): any[] => {
  let out: any[] = [];
  for (let i = 0; i < n; i++) {
    out = out.concat(array);
  }
  return out;
};

export const deckManaDistributionData = (cards: DeckCard[]): any[] => {
  const allColors = cards
    .map((dc) => {
      if (!dc.data.colors.length) {
        return repeat([ManaColorEnum.Colorless], dc.quantity);
      }
      return repeat(dc.data.colors, dc.quantity);
    })
    .flat();

  const colorMapping = ManaColorChartColorMapping();
  const occurrences = getOccurrences<ManaColorEnum>(allColors);
  const data: any[] = [];
  occurrences.forEach((v, k) => {
    data.push({
      value: occurrences.get(k),
      name: ManaColorNameMap[k],
      raw: k,
      itemStyle: {
        color: colorMapping.get(k),
      },
    });
  });
  return data;
};

export const deckTypesDistributionData = (cards: DeckCard[]): any[] => {
  const allTypes = cards.map((dc) => repeat(dc.data.types, dc.quantity)).flat();
  const occurrences = getOccurrences<string>(allTypes);
  const data: any[] = [];
  occurrences.forEach((v, k) => {
    data.push({
      value: occurrences.get(k),
      raw: k,
      name: k,
    });
  });
  return data;
};

export const deckLandDistributionData = (cards: DeckCard[]): any[] => {
  const landColors = cards
    .map((dc) => {
      if (!dc.data.colorIdentity.length) {
        return repeat([ManaColorEnum.Colorless], dc.quantity);
      }
      return repeat(dc.data.colorIdentity, dc.quantity);
    })
    .flat();

  const colorMapping = ManaColorChartColorMapping();
  const data: any[] = [];
  const occurrences = getOccurrences<ManaColorEnum>(landColors);
  occurrences.forEach((v, k) => {
    data.push({
      value: occurrences.get(k),
      name: ManaColorNameMap[k],
      raw: k,
      itemStyle: {
        color: colorMapping.get(k),
      },
    });
  });
  return data;
};

export const deckManaValueDistributionData = (cards: DeckCard[]): any[] => {
  const uniqManaValues = uniqueManaValues(cards.map((dc) => dc.data));

  const allManaValues = cards
    .map((dc) => repeat([dc.data.manaValue], dc.quantity))
    .flat();
  const occurrences = getOccurrences<number>(allManaValues);

  const data: any[] = [];
  Array.from(uniqManaValues)
    .sort((a, b) => a - b)
    .forEach((v) => {
      data.push({
        value: occurrences.get(v),
        name: v.toString(),
        raw: v,
      });
    });

  return data;
};

export const uniqueManaValues = (cards: MagicCard[]): Set<number> => {
  const res = cards
    .map((c) => c.manaValue)
    .flat(1)
    .reduce((acc, curr) => {
      acc.add(curr);
      return acc;
    }, new Set<number>());

  return res;
};

export const deckManaValueDistributionXAxis = (cards: DeckCard[]): any[] => {
  return Array.from(uniqueManaValues(cards.map((dc) => dc.data))).sort(
    (a, b) => a - b
  );
};

export const deckManaIdentity = (cards: DeckCard[]): string[] => {
  const cardColors = cards
    .map((dc) =>
      dc.data.colors.map((ci) => ci.toLowerCase() || ManaColorEnum.Colorless)
    )
    .flat();
  const set = new Set(cardColors);
  return Array.from(set).sort((a, b) => a.localeCompare(b));
};

export enum ManaColorCombination {
  B = "b",
  U = "u",
  G = "g",
  R = "r",
  W = "w",
  UW = "uw",
  BW = "bw",
  BU = "bu",
  RU = "ru",
  BG = "bg",
  BR = "br",
  RW = "rw",
  GR = "gr",
  GU = "gu",
  GW = "gw",
  GUW = "guw",
  BUW = "buw",
  BRU = "bru",
  BGR = "bgr",
  GRW = "grw",
  BGW = "bgw",
  RUW = "ruw",
  BGU = "bgu",
  BRW = "brw",
  GRU = "gru",
  BGRU = "bgru",
  BGRW = "bgrw",
  GRUW = "gruw",
  BGUW = "bguw",
  BRUW = "bruw",
  BGRUW = "bgruw",
}

const manaColorsToManaFontIndicator = new Map<ManaColorCombination, string>([
  [ManaColorCombination.B, "b"],
  [ManaColorCombination.U, "u"],
  [ManaColorCombination.G, "g"],
  [ManaColorCombination.R, "r"],
  [ManaColorCombination.W, "w"],
  [ManaColorCombination.UW, "wu"],
  [ManaColorCombination.BW, "wb"],
  [ManaColorCombination.BU, "ub"],
  [ManaColorCombination.RU, "ur"],
  [ManaColorCombination.BG, "bg"],
  [ManaColorCombination.BR, "br"],
  [ManaColorCombination.RW, "rw"],
  [ManaColorCombination.GR, "rg"],
  [ManaColorCombination.GU, "gu"],
  [ManaColorCombination.GW, "gw"],
  [ManaColorCombination.GUW, "wug"],
  [ManaColorCombination.BUW, "ubw"],
  [ManaColorCombination.BRU, "bru"],
  [ManaColorCombination.BGR, "rgb"],
  [ManaColorCombination.GRW, "gwr"],
  [ManaColorCombination.BGW, "wbg"],
  [ManaColorCombination.RUW, "urw"],
  [ManaColorCombination.BGU, "bgu"],
  [ManaColorCombination.BRW, "rwb"],
  [ManaColorCombination.GRU, "gur"],
  [ManaColorCombination.BGRU, "ubrg"],
  [ManaColorCombination.BGRW, "brgw"],
  [ManaColorCombination.GRUW, "rgwu"],
  [ManaColorCombination.BGUW, "gwub"],
  [ManaColorCombination.BRUW, "wubr"],
  [ManaColorCombination.BGRUW, "5"],
]);

export const manaColorsToNames = new Map<ManaColorCombination, string>([
  [ManaColorCombination.B, "Black"],
  [ManaColorCombination.U, "Blue"],
  [ManaColorCombination.G, "Green"],
  [ManaColorCombination.R, "Red"],
  [ManaColorCombination.W, "White"],
  [ManaColorCombination.UW, "Azorius"],
  [ManaColorCombination.BW, "Orzhov"],
  [ManaColorCombination.BU, "Dimir"],
  [ManaColorCombination.RU, "Izzet"],
  [ManaColorCombination.BG, "Golgari"],
  [ManaColorCombination.BR, "Rakdos"],
  [ManaColorCombination.RW, "Boros"],
  [ManaColorCombination.GR, "Gruul"],
  [ManaColorCombination.GU, "Simic"],
  [ManaColorCombination.GW, "Selesnya"],
  [ManaColorCombination.GUW, "Bant"],
  [ManaColorCombination.BUW, "Esper"],
  [ManaColorCombination.BRU, "Grixis"],
  [ManaColorCombination.BGR, "Jund"],
  [ManaColorCombination.GRW, "Naya"],
  [ManaColorCombination.BGW, "Abzan"],
  [ManaColorCombination.RUW, "Jeskai"],
  [ManaColorCombination.BGU, "Sultai"],
  [ManaColorCombination.BRW, "Mardu"],
  [ManaColorCombination.GRU, "Temur"],
  [ManaColorCombination.BGRU, "Glint"],
  [ManaColorCombination.BGRW, "Dune"],
  [ManaColorCombination.GRUW, "Ink"],
  [ManaColorCombination.BGUW, "Witch"],
  [ManaColorCombination.BRUW, "Yore"],
  [ManaColorCombination.BGRUW, "5"],
]);
export const sagaPairs = {
  I: "1",
  II: "2",
  III: "3",
  IV: "4",
  V: "5",
  VI: "6",
  VII: "7",
  VIII: "8",
  IX: "9",
  X: "10",
};
export const planesWalkerPairs = {
  "[+1]": "1",
  "[+2]": "2",
  "[+3]": "3",
  "[+4]": "4",
  "[+5]": "5",
  "[+6]": "6",
  "[+7]": "7",
  "[+8]": "8",
  "[+9]": "9",
  "[+10]": "10",
  "[+11]": "11",
  "[+12]": "12",
  "[+13]": "13",
  "[+14]": "14",
  "[+15]": "15",
  "[+16]": "16",
  "[+17]": "17",
  "[+18]": "18",
  "[+19]": "19",
  "[+20]": "20",
  "[0]": "0",
  "[−1]": "1",
  "[−2]": "2",
  "[−3]": "3",
  "[−4]": "4",
  "[−5]": "5",
  "[−6]": "6",
  "[−7]": "7",
  "[−8]": "8",
  "[−9]": "9",
  "[−10]": "10",
  "[−11]": "11",
  "[−12]": "12",
  "[−13]": "13",
  "[−14]": "14",
  "[−15]": "15",
  "[−16]": "16",
  "[−17]": "17",
  "[−18]": "18",
  "[−19]": "19",
  "[−20]": "20",
};
export const manaSymbolsPairs = {
  "{T}": "tap",
  "{Q}": "untap",
  "{E}": "e",
  "{0}": "0",
  "{1}": "1",
  "{2}": "2",
  "{3}": "3",
  "{4}": "4",
  "{5}": "5",
  "{6}": "6",
  "{7}": "7",
  "{8}": "8",
  "{9}": "9",
  "{10}": "10",
  "{11}": "11",
  "{12}": "12",
  "{13}": "13",
  "{14}": "14",
  "{15}": "15",
  "{16}": "16",
  "{17}": "17",
  "{18}": "18",
  "{19}": "19",
  "{20}": "20",
  "{W/U}": "wu",
  "{W/B}": "wb",
  "{B/R}": "br",
  "{B/G}": "bg",
  "{U/B}": "ub",
  "{U/R}": "ur",
  "{R/G}": "rg",
  "{R/W}": "rw",
  "{G/W}": "gw",
  "{G/U}": "gu",
  "{2/W}": "2w",
  "{2/U}": "2u",
  "{2/B}": "2b",
  "{2/R}": "2r",
  "{2/G}": "2g",
  "{W/P}": "wp",
  "{U/P}": "up",
  "{B/P}": "bp",
  "{R/P}": "rp",
  "{G/P}": "gp",
  "{W}": "w",
  "{U}": "u",
  "{B}": "b",
  "{R}": "r",
  "{G}": "g",
  "{C}": "c",
  "{S}": "s",
  "{X}": "x",
};
export const toManaFontColorIndicator = (
  colors: ManaColorCombination
): string => {
  return manaColorsToManaFontIndicator.get(colors) as string;
};

export const manaCostToManaFontClasses = (manaCost: string): string[] => {
  const result: string[] = [];

  const regex = /{(.{1,3})}/g;
  const matches = manaCost.match(regex);

  if (matches) {
    matches.forEach((match) => {
      const cost = match.replaceAll(/[{}]/g, "");

      // if it's a split mana cost, we need to handle it as such
      if (cost.includes("/")) {
        const noSlash = cost.replace("/", "");
        result.push(noSlash.toLocaleLowerCase());
      } else {
        result.push(cost.toLocaleLowerCase());
      }
    });
  }

  return result;
};

export const mainboardGroupingToManaFontClasses = (group: string): string[] => {
  const result: string[] = [];

  const matches = group.split(",");
  if (matches) {
    matches.forEach((m) => {
      // if it's a split mana cost, we need to handle it as such
      if (m.includes("/")) {
        const noSlash = m.replace("/", "");
        result.push(noSlash.toLocaleLowerCase());
      } else {
        result.push(m.toLocaleLowerCase());
      }
    });
  }

  if (!matches.length) {
    matches.push(ManaColorEnum.Colorless.toLocaleLowerCase());
  }

  return result;
};

export const totalDeckPrice = (cards: DeckCard[]): number => {
  const prices = cards.map(
    (dc) => (dc.data.price?.value.default || 0) * dc.quantity
  );
  return prices.reduce((a, b) => a + b, 0);
};

export const firstUpper = (s: string) => `${s[0].toUpperCase()}${s.slice(1)}`;

export const shuffleArray = (arr: any[], count: number = 10) => {
  for (let x = 0; x < count; x++) {
    for (let i = arr.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
  }
};

export const printDeck = (deck: DeckDetails | SubmittedDeckItem) => {
  const locationGroups = _groupBy(
    deck.cards,
    (dc: DeckCard) => DeckLocationNameMap[dc.location]
  );

  const deckList = Object.entries<DeckCard[]>(locationGroups).map(
    ([location, cards]: [string, DeckCard[]]) => {
      return `${location}\n${cards.reduce((prev, curr) => {
        return prev + `${curr.quantity} ${curr.data.name}\n`;
      }, "")}`;
    }
  );

  const element = document.createElement("a");
  const file = new Blob([deckList.join("\n")], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  if ("name" in deck) {
    element.download = `${deck.name}.txt`;
  } else {
    element.download = `${deck.archetypeSlug}.txt`;
  }
  document.body.appendChild(element);
  element.click();
};

export const toDateFormat = (dateString: string | Date) => {
  return new Date(dateString).toLocaleDateString("en", {
    year: "2-digit",
    month: "short",
    day: "numeric",
  });
};

export const toDateFormatExtended = (dateString: string) => {
  return new Date(dateString).toLocaleDateString("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};

export const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export const activeButton = (pathname: any, route: string | string[]) => {
  if (Array.isArray(route)) {
    return route.some(
      (r) => pathname === r || pathname.startsWith(r) || pathname.endsWith(r)
    );
  }
  if (route === "/") {
    return pathname === route;
  }
  return pathname.endsWith(route) || pathname.startsWith(route);
};

export const getRandomIntInRange = (min: number, max: number): number => {
  const cmin = Math.ceil(min);
  const fmax = Math.floor(max);
  return Math.floor(Math.random() * (fmax - cmin) + cmin); // The maximum is exclusive and the minimum is inclusive
};

export const getRandomBooleanWithRate = (rate: number): boolean => {
  const rand = Math.random();

  if (rand <= rate) {
    return true;
  }
  return false;
};

//replace all special characters with dashes in the card.name variable
//this is needed for the url
//example: "Aether Vial" -> "Aether-Vial"
//example: "Aether Vial // Aether Vial" -> "Aether-Vial-Aether-Vial"
export const encodedCardName = (cardName: string) =>
  cardName.replace(/[^a-zA-Z0-9]/g, "-");

// generate tooltips for charts based on data and data points of Magic Cards
export const generateTooltipsForMagicCards = (
  data: any[],
  origData: any[],
  theme: any,
  tooltipItemStyle: any,
  filterFunction: (d: any) => (c: any) => boolean
): Map<string, string> => {
  const tooltips = new Map<string, string>();
  data.forEach((d) => {
    const items = origData.filter(filterFunction(d)).map((c) => {
      if (c.quantity > 1) {
        return `<li style="${tooltipItemStyle}">
                            ${c.data.name} <span style="color: ${theme.palette.secondary.main}">x${c.quantity}</span>
                        </li>`;
      }

      return `<li style="${tooltipItemStyle}">${c.data.name}</li>\n`;
    });
    tooltips.set(
      d.name,
      `<div>
        <span style="color: ${theme.palette.primary.main}">${d.name}</span>
        <ul style="padding: 0px 8px 0 24px; margin-top: 4px;">${items.join(
          "\n"
        )}</ul>
      </div>`
    );
  });
  return tooltips;
};

export const groupedCardsInLocation = (
  location: DeckLocationEnum,
  grouping: CardGrouping,
  data: DeckCard[]
) => {
  const cards = data.filter((dc) => dc.location === location);

  const isSpecialLocation = [
    DeckLocationEnum.Commander,
    DeckLocationEnum.Sideboard,
    DeckLocationEnum.Considering,
    DeckLocationEnum.Companion,
  ].includes(location);

  if (isSpecialLocation) {
    if (location === DeckLocationEnum.Sideboard) {
      cards.unshift(
        ...data.filter((dc) => dc.location === DeckLocationEnum.Companion)
      );
    }
    const specialLocCards = _groupBy(cards, () => [
      DeckLocationNameMap[location],
    ]);

    return {
      ...specialLocCards,
    };
  }

  const lands = cards.filter((c) => c.data.types.includes("Land"));
  const landTypeGroup = _groupBy(lands, (c) => c.data.types.join(", "));
  const cardsNotLands = cards.filter((c) => !c.data.types.includes("Land"));
  const sortedByType = cardsNotLands
    .slice()
    .sort((a, b) => a.data.types[0].localeCompare(b.data.types[0]));

  switch (grouping) {
    case CardGrouping.FirstType:
      return {
        ..._groupBy(sortedByType, (c) => c.data.types),
        ...landTypeGroup,
      };

    case CardGrouping.Color:
      return {
        ..._groupBy(
          sortedByType,
          (c) => c.data.colors || [ManaColorEnum.Colorless]
        ),
        ...landTypeGroup,
      };
    case CardGrouping.ManaValue:
      return {
        ..._groupBy(sortedByType, (c) => c.data.manaValue),
        ...landTypeGroup,
      };
  }
};

export const totalLocationCards = (
  location: DeckLocationEnum,
  cards: DeckCard[]
) => {
  return cards
    .filter((dc) => dc.location === location)
    .reduce((total, b) => total + b.quantity, 0);
};

export const cardsInLocation = (
  location: DeckLocationEnum,
  cards: DeckCard[]
) => {
  return cards.filter((dc) => dc.location === location);
};

export function isUserDeck(
  deck: DeckDetails | SubmittedDeckItem
): deck is DeckDetails {
  return (deck as DeckDetails).user !== undefined;
}

// Utility function to truncate text
export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

export const formatDateChatBubble = (timestamp: Date) => {
  const now = new Date();
  const date = new Date(timestamp);

  const timeString = format(date, "HH:mm"); // Ensuring 24-hour format

  const daysDiff = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
  );
  const isSameDay = now.toDateString() === date.toDateString();
  const isYesterday = daysDiff === 1 && now.getDate() !== date.getDate();

  if (isSameDay) {
    return `Today ${timeString}`;
  } else if (isYesterday) {
    return `Yesterday ${timeString}`;
  } else if (daysDiff < 7) {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return `${dayNames[date.getDay()]} ${timeString}`;
  } else {
    const dateString = format(date, "MMMM dd, yyyy");
    return `${dateString} ${timeString}`;
  }
};
export const clerkImage = (
  preferredAvatar: string,
  height: number = 32,
  width: number = 32,
  quality: number = 100
): string => {
  const params = new URLSearchParams();
  params.set("height", `${height}`);
  params.set("width", `${width}`);
  params.set("quality", `${quality}`);
  // params.set("fit", "crop");

  return `${preferredAvatar}?${params.toString()}`;
};

export function isCollectionCard(
  card: CollectionCard | TradeItem
): card is CollectionCard {
  return (card as CollectionCard).purchasePrice !== undefined;
}

export function isTradeItem(
  card: CollectionCard | TradeItem
): card is TradeItem {
  return (card as TradeItem).listId !== undefined;
}
