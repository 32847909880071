import { Box, Button } from "@mui/material";
import { CardMediaImage } from "modules/common/components/CardMediaImage";
import React from "react";
import { MagicCard } from "modules/cards/model/card";
import Link from "next/link";
import { CardMarketIcon } from "modules/common/components/Icons/CardMarketIcon";

interface Props {
  card: MagicCard;
  flipped: boolean;
  setFlipped: (flipped: boolean) => void;
  isFoil?: boolean;
}

export const CardImageMarketIcons = ({
  flipped,
  setFlipped,
  card,
  isFoil,
}: Props) => {
  const cardPrice = isFoil
    ? card.price?.value.foil && `€${card.price.value.foil.toFixed(2)}`
    : card.price?.value.default && `€${card.price.value.default.toFixed(2)}`;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
    >
      <CardMediaImage
        flipped={flipped}
        setFlipped={setFlipped}
        card={card}
        isFoil={isFoil}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        {card?.purchaseUrls?.cardmarket ? (
          <Link href={card.purchaseUrls.cardmarket} target={"_blank"}>
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              startIcon={<CardMarketIcon />}
              size={"small"}
            >
              Buy @ Cardmarket {cardPrice}
            </Button>
          </Link>
        ) : null}
        {/*{card?.purchaseUrls?.cardKingdom ? (
          <IconButton href={card.purchaseUrls.cardKingdom} target={"_blank"}>
            <Image
              src={"/img/clean_cardkingdom_logo.png"}
              alt={"CK"}
              width={"32"}
              height={"32"}
            />
            asd
          </IconButton>
        ) : null}*/}
        {/*{card.data.purchaseUrls.tcgPlayer ?*/}
        {/*    <IconButton href={card.data.purchaseUrls.tcgPlayer} target={"_blank"}>*/}
        {/*        <Image src={require("/public/img/clean_tcgplayer_logo_white.png")}*/}
        {/*               alt={"TCG"}*/}
        {/*               width={"30%"}*/}
        {/*               height={"30%"}*/}
        {/*        />*/}
        {/*    </IconButton> : null}*/}
      </Box>
    </Box>
  );
};
